@import 'static/styles/common/vars';

.label-prefix {
  margin-right: 8px;
}

.gacha--img-detail {
  border: 1px solid @SECONDARY;
  border-radius: 12px;
  padding: 8px;
  position: relative;

  &--remove {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    span {
      color: @ERROR;
      cursor: pointer;
      font-size: 24px;
    }
  }

  &:hover {
    .gacha--img-detail--remove {
      opacity: 1;
      visibility: visible;
    }
  }
}

.gacha--popover--add-reward {
  .space-3-popover-inner-content {
    width: 400px;
    padding: 8px;
    border-radius: 8px;
    background: #0a0c0e;
  }
}

.btn-square {
  width: 117px;
  height: 117px !important;
  border: 1px solid @SECONDARY!important;
  border-radius: 12px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 !important;
  }
}

.seo-section {
  margin-bottom: 24px;

  .space-3-typography {
    color: #b2bfcb;
  }
}

@ant-prefix: space-3;