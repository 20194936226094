.shipping-statistic {
  >.space-3-col {
    border-right: 2px solid #1B2526 ;
    &:last-child {
      border-right: none;
    }
  }
}

.action-modal {
  .space-3-modal-content {
    border-radius: 12px;
  }
}

@ant-prefix: space-3;