.sp3-loader-container {
  position: relative;
  .sp3-inner-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #121416f0;
    z-index: 1;
  }

  .sp3-loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    .face {
      position: absolute;
      border-radius: 50%;
      border-style: solid;
      animation: animate023845 2s linear infinite;
      &:nth-child(1) {
        width: 100%;
        height: 100%;
        color: gold;
        border-color: currentColor transparent transparent currentColor;
        border-width: 0.2em 0.2em 0em 0em;
        --deg: -45deg;
        animation-direction: normal;
      }
      &:nth-child(2) {
        width: 70%;
        height: 70%;
        color: lime;
        border-color: currentColor currentColor transparent transparent;
        border-width: 0.2em 0em 0em 0.2em;
        --deg: -135deg;
        animation-direction: reverse;
      }
      .circle {
        position: absolute;
        width: 50%;
        height: 0.1em;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: rotate(var(--deg));
        transform-origin: left;
        &::before {
          position: absolute;
          top: -0.5em;
          right: -0.5em;
          content: '';
          width: 1em;
          height: 1em;
          background-color: currentColor;
          border-radius: 50%;
          box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em,
            0 0 0 0.5em rgba(255, 255, 0, 0.1);
        }
      }
    }
  }

  // Stick loader
  .sp3-stick-loader {
    --speed-of-animation: 0.9s;
    --gap: 6px;
    --first-color: #4c86f9;
    --second-color: #49a84c;
    --third-color: #f6bb02;
    --fourth-color: #f6bb02;
    --fifth-color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    span {
      height: inherit;
      background: var(--first-color);
      animation: stickLoader var(--speed-of-animation) ease-in-out infinite;
      &:nth-child(2) {
        background: var(--second-color);
        animation-delay: -0.8s;
      }
      &:nth-child(3) {
        background: var(--third-color);
        animation-delay: -0.7s;
      }
      &:nth-child(4) {
        background: var(--fourth-color);
        animation-delay: -0.6s;
      }
      &:nth-child(5) {
        background: var(--fifth-color);
        animation-delay: -0.5s;
      }
    }
  }

  // Page loader
  .sp3-page-loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #12141640;
    backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
}

@keyframes animate023845 {
  to {
    transform: rotate(1turn);
  }
}

@keyframes stickLoader {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}

@ant-prefix: space-3;