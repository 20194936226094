@import '~antd/dist/antd.less';

.table-border-collapse {
  table {
    border-spacing: 0 12px !important;
  }
  thead {
    tr {
      th {
        padding: 0 6px;
        color: #4d5a66;
        &:nth-of-type(2) {
          padding: 0;
        }
      }
    }
  }
  tbody {
    tr {
      background: #0f191b;
      td {
        border: none;
        // border-top: 1px solid transparent;
        // border-bottom: 1px solid transparent;
        // &:first-child {
        //   border-left: 1px solid transparent;
        // }
        // &:last-child {
        //   border-right: 1px solid transparent;
        // }
        padding: 12px 6px;
        &.@{ant-prefix}-table-selection-column {
          padding: 8px 18px 8px;
        }
        &:nth-of-type(2) {
          padding: 12px 0;
        }
        * {
          color: #b2bfcb;
        }
        .space-3-dropdown-button {
          .space-3-btn-compact-first-item {
            display: none;
          }
          .space-3-btn-compact-last-item {
            width: auto;
            height: auto;
            padding: 0;
          }
        }
      }
      &:hover {
        background: #142224;
        // td {
        // border-top-color: #42bab0;
        // border-bottom-color: #42bab0;
        // &:first-child {
        //   border-left-color: #42bab0;
        // }
        // &:last-child {
        //   border-right-color: #42bab0;
        // }
        // }
      }
    }
  }
  .@{ant-prefix}-checkbox-wrapper {
    .@{ant-prefix}-checkbox-inner {
      background: transparent;
      border: 1.5px solid #4d5a66;
      &::after {
        display: block !important;
      }
    }
    &.space-3-checkbox-wrapper-checked {
      .@{ant-prefix}-checkbox-inner {
        background: #42bab0;
        border-color: transparent;
      }
    }
  }
}

@ant-prefix: space-3;