@import '~antd/dist/antd.variable.less';

.@{ant-prefix}-upload-list {
  display: flex !important;
  flex-wrap: wrap;
  row-gap: 8px;
  .@{ant-prefix}-upload-list-picture-card-container {
    margin: 0 8px 0 0 !important;
  }
  .@{ant-prefix}-upload {
    background: transparent !important;
    border-color: #42bab0 !important;
    margin: 0 !important;
  }
  .@{ant-prefix}-upload-list-item {
    border-radius: 4px !important;
    border-color: #42bab0 !important;
  }
  .@{ant-prefix}-btn {
    padding: 0 !important;
  }
}

@ant-prefix: space-3;