.community-table {
  tbody {
    tr {
      td {
        &:last-child {
          .space-3-space-compact {
            justify-content: center;
          }
        }
        &:nth-of-type(2) {
          padding: 12px 12px 12px 0;
        }
      }
    }
  }
}

@ant-prefix: space-3;