.scrollbar {
  overflow: auto; // To let lazyload realize the container
  &:hover {
    overflow: overlay; // Avoid scrollbar occupying space
  }
}

// Hide all inessential
.scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar::-webkit-scrollbar-track-piece {
  display: none;
}
.scrollbar::-webkit-scrollbar-corner {
  display: none;
}

// Customize the bar
.scrollbar:hover::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  background: #242f30;
  border-radius: 4px;
  &:hover {
    background: #242f30;
  }
}
