@import '~antd/dist/antd.less';

.sidebar-container {
  background: transparent !important;
  width: 254px !important;
  .@{ant-prefix}-menu-item,
  .@{ant-prefix}-menu-submenu {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    height: auto !important;
  }

  &[class*='collapsed'] {
    width: 80px !important;
  }
  &.mobile-sidebar {
    width: 100% !important;
    li[class*='item-selected'] {
      background: #3e3d3d6b !important;
    }
  }
}

@ant-prefix: space-3;