.transparent-modal {
  .space-3-modal-content {
    border: none;
    background: transparent;
    box-shadow: none !important;
  }
}

.clip-path-container {
  padding: 1px;
  width: fit-content;
  clip-path: polygon(
    20px 0,
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%,
    0 20px
  );
  background: linear-gradient(
    145deg,
    #1d232a,
    #09bcc681,
    #54d77ead,
    #c4ff129a,
    #409d119d,
    #09bcc694
  ) !important;

  .daily-reward-title {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 80px;
    clip-path: polygon(
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%,
      0 20px
    );
    background: #0f191b;
  }
}

.daily-rewards-container {
  .daily-rewards-contents {
    padding: 16px;
    background: #0f191b;

    .space-3-card-body {
      padding: 0;
    }

    .daily-rewards-item {
      display: flex;
      justify-content: center;
      align-items: center;

      &.rewards-item-streak {
        justify-content: start !important;
      }

      .streak-text {
        background: #1b2526;
        padding: 6px 15px;
        border-radius: 4px;
        font-weight: 700;

        &.claimed {
          color: #42bab0;
        }

        &.missed {
          color: #4d5a66;
        }

        &.not-performed {
          color: #db4646;
        }
      }
    }
  }

  .daily-rewards-actions {
    padding: 16px;
    background: #0f191b;

    button {
      font-weight: 700;
      font-size: 18px;
      color: #1e1e1e;
    }
  }

  .streak-rewards {
    min-height: 200px;
    background: #0f191b;
  }
}

.card-streak {
  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #091112;
    transform: translateX(-50%);
  }
  &-active {
    &::after {
      border-bottom: 20px solid #42bab0;
    }
  }
}
.card-daily-rewards {
  .space-3-image {
    position: absolute;
    bottom: 6px;
    right: 6px;
    border-radius: 50%;

    .mask-checked {
      background: #004842;
      border-radius: 50%;
      border: 2px solid #42bab0;
    }
  }
}

.daily-rewards-streak-card {
  border: 1px solid !important;
  border-image-slice: 1 !important;
  border-image-source: linear-gradient(
    162.05deg,
    rgba(9, 190, 189, 1) 0.85%,
    rgba(3, 194, 129, 1) 43.51%,
    rgba(106, 215, 67, 1) 62.1%,
    rgba(106, 215, 67, 0.4) 94.11%
  ) !important;
}

.card-border-animation {
  animation: transformBoxShadow 3s linear infinite;
}

@keyframes transformBoxShadow {
  0% {
    box-shadow: -4px -4px 16px 0 #a9ea7d8a, 4px 4px 16px 0 #58cffb8a;
  }
  25% {
    box-shadow: 4px -4px 16px 0 #a9ea7d8a, -4px 4px 16px 0 #58cffb8a;
  }
  50% {
    box-shadow: 4px 4px 16px 0 #a9ea7d8a, -4px -4px 16px 0 #58cffb8a;
  }
  75% {
    box-shadow: -4px 4px 16px 0 #a9ea7d8a, 4px -4px 16px 0 #58cffb8a;
  }
  100% {
    box-shadow: -4px -4px 16px 0 #a9ea7d8a, 4px 4px 16px 0 #58cffb8a;
  }
}

.nft-selected-section {
  .space-3-input-number-handler-wrap {
    display: block !important;
  }

  .space-3-input-number-input {
    border-radius: 2px;
    border: 1px solid #42bab0;
    background: #0f191b;
    width: 80px;
  }
  .nft-item--title {
    width: 140px;
  }
}

@ant-prefix: space-3;