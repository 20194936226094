.card-bounty-report {
  .bounty-rp-item {
    &:not(:first-child) {
      padding-top: 12px;
    }
    &:not(:last-child) {
      padding-bottom: 12px;
      border-bottom: 1px solid #29333e;
    }
  }
}

@ant-prefix: space-3;