.ccp-chart {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .hole {
    background: #12131b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explain {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

@ant-prefix: space-3;