@import 'static/styles/common/vars.less';

.acceler8-task-crud-container {
  .select-game {
    width: 200px;
  }

  .form-partner-task {
    &-container {
      max-width: 700px;
    }

    &--btn-submit {
      margin-top: 24px;
    }
  }

  .remove-icon {
    color: @ERROR;
  }
}

@ant-prefix: space-3;