.sidebar {
  flex: 1;
  border-right: 2px solid #242f30;
  background: #070d0e;
  .space-3-menu-submenu {
    &-selected {
      .space-3-menu-submenu-title {
        background: #0f191b;
      }
    }
    .space-3-menu-submenu-title {
    }
  }

  .space-3-menu-item {
    &:not(.space-3-menu-item-only-child) {
      &.space-3-menu-item-selected {
        background: #0f191b !important;
      }
    }
  }
}

.space-middle-icon {
  .space-3-space-item {
    display: flex !important;
  }
}

.card-gradient {
  border: 1px solid !important;
  border-image-slice: 1 !important;
  cursor: pointer;
  border-image-source: linear-gradient(
    162.05deg,
    rgba(106, 215, 67, 0.4) 0.85%,
    rgba(4, 194, 138, 0.0277778) 43.51%,
    rgba(3, 194, 129, 0) 62.1%,
    rgba(106, 215, 67, 0.4) 94.11%
  ) !important;
}

.rice {
  color: #ebebeb !important;
}

.primary {
  color: #40b8ac !important;
}

.disable-tab-gap {
  ::before {
    display: none;
  }
}
.admin-primary-btn {
  background: #42bab0 !important;
  color: #1e1e1e !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}
.modal-gray {
  .space-3-modal-content {
    background: rgba(15, 25, 27, 1);
    .space-3-modal-header {
      background: rgba(29, 35, 43, 0.9);
    }
  }
}

.card-gray {
  background: rgba(29, 35, 43, 0.9) !important;
}

.blue-button {
  background: #42bab0;
  color: #1e1e1e;
  &:hover {
    background: #42bab0;
    color: #1e1e1e;
  }
  &:focus {
    background: #42bab0;
    color: #1e1e1e;
  }
}

.gradient-text {
  display: inline;
  background: linear-gradient(
    88.54deg,
    #4f8463 1.91%,
    #49e9db 48.66%,
    #d8c93e 76.1%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@ant-prefix: space-3;