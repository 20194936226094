.csv-list-section {
  .space-3-table-tbody {
    .space-3-table-cell {
      padding: 12px;
    }

    .remove-btn {
      padding: 0;
      width: 18px;
      height: 18px;
      color: #fb6161;
    }
  }
}

@ant-prefix: space-3;