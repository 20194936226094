h1.@{ant-prefix}-typography {
  font-weight: 700;
  @media (max-width: 767.9px) {
    font-size: 30px;
  }
}

h2.@{ant-prefix}-typography {
  font-weight: 800 !important;
  @media (max-width: 767.9px) {
    font-size: 24px !important;
  }
}

h3.@{ant-prefix}-typography {
  font-weight: 700 !important;
  @media (max-width: 767.9px) {
    font-size: 20px !important;
  }
}

h4.@{ant-prefix}-typography {
  font-weight: 700 !important;
  font-size: 18px;
  @media (max-width: 767.9px) {
    font-size: 16px !important;
  }
}

h5.@{ant-prefix}-typography {
  font-weight: 400 !important;
  @media (max-width: 767.9px) {
    font-size: 14px !important;
  }
}

span.@{ant-prefix}-typography {
  &.caption {
    font-size: 12px !important;
  }
}
