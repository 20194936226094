/** Pagination */
.btn-pagination {
  padding: 6px !important;
  cursor: pointer;
  background: transparent !important ;
  border: 1px solid transparent;

  &:hover {
    border-color: #42bab0;
  }
}
.space-3-pagination {
  &-item {
    padding: 0 !important;
    min-width: 32px !important;
    height: 32px !important;
    border-radius: unset !important;
    border: 1px solid transparent;
    &-link:hover,
    &:hover,
    &-active {
      border-color: #42bab0;
    }
    a {
      padding: 0;
      line-height: 32px;
    }
  }

  &-prev,
  &-next {
    min-width: 32px !important;
    height: 32px !important;
    button {
      border: none !important;
      line-height: 32px;
      font-weight: 700;
    }
  }
}

// Space vertical
.wrap-space-vertical {
  > .space-3-space-item {
    width: 100%;
  }
}

@ant-prefix: space-3;