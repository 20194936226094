.preview-community-section {
  &-overview {
    .space-3-collapse-header {
      background: #a246db !important;
    }
  }

  &-system-featured {
    .space-3-collapse-header {
      background: #db4646 !important;
    }
  }

  &-featured-content {
    .space-3-collapse-header {
      background: #5866f2 !important;
    }
  }

  &-team-members {
    .space-3-collapse-header {
      background: #c28222 !important;
    }
  }

  &-company {
    .space-3-collapse-header {
      background: #42bab0 !important;
    }
  }

  &-official-link {
    .space-3-collapse-header {
      background: #cc0066 !important;
    }
  }
}

@ant-prefix: space-3;