.sp3-lazyload-img {
  .space-3-skeleton,
  .space-3-skeleton-image {
    width: 100% !important;
    height: 100% !important;
  }
  .space-3-skeleton-image {
    backdrop-filter: blur(20px);
  }
}

@ant-prefix: space-3;