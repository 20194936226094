.on-system-section {
  .space-3-table-cell {
    padding: 12px;
  }
  .remove-btn {
    padding: 0;
    width: 18px;
    height: 18px;
    color: #fb6161;
  }
}

.confirm-delete-modal {
  .space-3-modal-content {
    border-radius: 12px;
    background: #14181c;
  }

  .confirm-title {
    color: #f1f2f3;
    font-size: 18px;
    font-weight: 500 !important;
    text-align: center;
  }

  .confirm-description {
    color: #a3a5ae;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
  }

  .cancel-btn {
    width: 50%;
    color: #7e7e86;
    font-size: 16px;
    font-weight: 500;
  }

  .delete-btn {
    width: 50%;
    color: #fb6161;
    font-size: 16px;
    font-weight: 600;
  }
}

@ant-prefix: space-3;