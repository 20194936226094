.sort-filter-container--dropdown {
  max-width: 375px;
  .space-3-dropdown-menu {
    background-color: #121416;
    padding: 24px;
    border-radius: 16px;

    .space-3-dropdown-menu-item {
      cursor: inherit;
      &:hover {
        background-color: initial;
      }

      .space-3-divider {
        margin-bottom: 0;
      }

      .space-3-checkbox {
        .space-3-checkbox-inner {
          background-color: initial;
          border: 1px solid #3b4355;
        }
      }

      .space-3-radio-wrapper-checked,
      .space-3-checkbox-wrapper-checked {
        color: #0fdbd1;
        .space-3-checkbox-inner {
          background-color: #0fdbd1 !important;
        }
      }

      .btn-reset {
        width: 100%;
        color: #4d5a66;
        background-color: #1c2023;
        border-radius: 8px;
      }
    }
  }
}

@ant-prefix: space-3;