.card-nft {
  cursor: pointer;
  &:hover {
    border-color: #42bab0 !important ;
  }
}

.delete-dropdown {
  position: absolute;
  top: 0;
  right: -12px;
  .space-3-dropdown-menu {
    width: 140px !important;
  }
  &_content {
    background: #1b2526;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: solid 1px #42bab0 !important ;
    }
  }
}

@ant-prefix: space-3;