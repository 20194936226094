@import '~antd/dist/antd.less';

.btn-new-task {
  .@{ant-prefix}-btn{
    width: 100% !important;
    height: auto;
    padding: 0;
  }
}

@ant-prefix: space-3;