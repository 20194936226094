.campaign-card {
  &_title {
    padding: 12px;
    height: 70px;
  }

  cursor: pointer;
  &:hover {
    border-color: #42bab0 !important ;
  }
}

@ant-prefix: space-3;