@import '~antd/dist/antd.variable.less';

.form-create-bounty {
  .@{ant-prefix}-tabs-nav {
    margin-bottom: 24px !important;
  }
}

.nft-item {
  padding-bottom: 6px;
  border-bottom: 1px solid #1e1e1e;
  .delete-nft {
    &:hover {
      color: #db4646;
    }
  }
}

.space-3-input-number:not(.show-number-handler) {
  width: 100% !important;
  .space-3-input-number-handler-wrap {
    display: none !important;
  }
}

@ant-prefix: space-3;