@import 'static/styles/common/vars';

.gacha--reward {
  &-container {
    padding: 16px;
    border-radius: 12px;
    background: @DARK;
    position: relative;

    &--remove {
      position: absolute !important;
      top: 10px;
      right: 12px;

      span {
        font-size: 20px;
      }
    }
  }

  &-content {
    > div:nth-last-child(2) {
      margin-bottom: 0 !important;
    }
  }
}

.btn-dashed {
  border-radius: 12px !important;
  border: 1px dashed @PRIMARY !important;
}

.space-3-popover-placement-bottom,
.space-3-popover-placement-top {
  padding: 0 !important;
}

@ant-prefix: space-3;