@import '~antd/dist/antd.less';

.reward-avatar {
  border: solid 0.5px #242f30 !important;
  background: #0d1516 !important;
  .@{ant-prefix}-avatar-string {
    margin: auto;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}

@ant-prefix: space-3;