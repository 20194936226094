@import 'static/styles/common/vars';

.badge--img-detail {
  border: 1px solid @SECONDARY;
  border-radius: 12px;
  padding: 8px;
  position: relative;

  &--remove {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    span {
      color: @ERROR;
      cursor: pointer;
      font-size: 24px;
    }
  }

  &:hover {
    .badge--img-detail--remove {
      opacity: 1;
      visibility: visible;
    }
  }
}

@ant-prefix: space-3;