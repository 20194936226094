.markdown-preview {
  i,
  i * {
    color: #a6a7ad;
  }
  em,
  em * {
    color: #a6a7ad;
  }
  code.inline-code {
    background-color: #19171c;
    color: #8b8792;
  }
  img {
    border-radius: 8px;
    max-width: 100%;
  }
  &
    *:not(
      h1,
      h1 *,
      h2,
      h2 *,
      h3,
      h3 *,
      h4,
      h4 *,
      h5,
      h5 *,
      pre,
      pre *,
      i,
      i *,
      em,
      em *
    ) {
    font-size: 14px;
  }
  h1,
  h1 * {
    font-size: 34px;
    font-weight: 700 !important;
  }
  h2,
  h2 * {
    font-size: 30px;
    font-weight: 700 !important;
  }
  h3,
  h3 * {
    font-size: 24px;
    font-weight: 700 !important;
  }
  h4,
  h4 * {
    font-size: 22px;
    font-weight: 700 !important;
  }
  h5,
  h5 * {
    font-size: 20px;
    font-weight: 700 !important;
  }
  i,
  i * {
    font-size: 18px;
    font-weight: 300;
  }
  em,
  em * {
    font-size: 14px;
    font-weight: 300;
  }
  pre,
  pre * {
    font-size: 14px;
  }
  p {
    margin-bottom: 0 !important;
  }
  table {
    tbody,
    td,
    th {
      border: 1px solid;
      padding: 4px 12px;
    }
    tr {
      border-bottom: 1px solid;
      vertical-align: baseline;
    }
    th {
      text-align: center;
    }
  }
  code.inline-code {
    font-size: 16px !important;
    border-radius: 8px;
    padding: 4px;
  }
  * {
    word-break: break-word;
  }
}

.line-break {
  white-space: pre-wrap;
}

.markdown-editor {
  background: #162021;
  border-radius: 4px;
  border: 1px solid transparent;
  .editor-toolbar {
    border: none;
    border-bottom: 1px solid #242f30;
    .active {
      background: #40b8ac2c;
      border-color: #242f30;
    }
  }
  .CodeMirror-wrap {
    border: none;
    background: transparent;
  }
  .CodeMirror-gutters {
    border-color: #242f30;
    background: transparent;
  }
  .CodeMirror-code {
    color: #fff;
  }
  .CodeMirror-cursor {
    border-color: #fff;
  }
  .CodeMirror-placeholder {
    color: #879db1 !important;
  }
  &:has(.CodeMirror-focused) {
    border-color: #40b8ac;
  }
  * {
    word-break: break-word;
  }
}

@ant-prefix: space-3;