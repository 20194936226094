.select-chain {
  // border: 1px solid #4d5a66 !important;
  .space-3-select-selector {
    background-color: transparent !important;
  }
}
.notification-badge {
  display: flex;
  align-self: center;
  justify-content: center;
  .space-3-badge-count {
    font-size: 6px;
    padding: 0;
    color: #000;
  }
}

@ant-prefix: space-3;