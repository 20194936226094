.community-dropdown {
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    min-width: 300px;
    background-color: #1b2526;
    border: 1px solid #29333d;
    border-radius: 6px;
  }

  &-input {
    margin-bottom: 8px;
  }
}

.community-toggle-btn {
  display: flex;
  align-items: center;
  height: auto;

  &-text {
    font-size: 14px;
    font-weight: 700;
  }

  &-icon {
    font-size: 18px;
    font-weight: 700;
  }
}

@ant-prefix: space-3;