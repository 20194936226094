.upload-file {
  border-color: #4d5a66 !important;
  padding: 27px !important;
  border-radius: 8px !important;
}
.error {
  border-color: #ff4d4f !important;
}

.remove-btn {
  &:hover {
    color: #db4646 !important;
  }
}

@ant-prefix: space-3;