.report-table {
  thead {
    tr {
      th:first-child {
        padding-left: 24px !important;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        padding-left: 24px !important;
      }
    }
  }
}

@ant-prefix: space-3;