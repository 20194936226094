.create-button {
  background-color: #0fdbd1 !important;
  border-radius: 8px !important;

  span {
    color: #090a0b;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
}

.create-button:hover {
  background-color: #0ec9c0 !important;
}

@ant-prefix: space-3;