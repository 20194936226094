@import 'static/styles/common/vars';

.reward-tier {
  &--item {
    border: 1px solid @SECONDARY;
    padding: 12px !important;
    border-radius: 8px !important;

    &--common {
      &.space-3-radio-wrapper-checked {
        border-color: @PRIMARY !important;
        .space-3-radio-checked::after {
          border-color: @PRIMARY !important;
        }

        .space-3-radio-input:focus + .space-3-radio-inner {
          border-color: @PRIMARY;
        }

        .space-3-radio-inner {
          border-color: @PRIMARY;
          &::after {
            background-color: @PRIMARY;
          }
        }
      }

      * {
        color: @PRIMARY !important;
      }
    }

    &--rare {
      &.space-3-radio-wrapper-checked {
        border-color: #568bf4 !important;
        .space-3-radio-checked::after {
          border-color: #568bf4 !important;
        }

        .space-3-radio-input:focus + .space-3-radio-inner {
          border-color: #568bf4;
        }

        .space-3-radio-inner {
          border-color: #568bf4;
          &::after {
            background-color: #568bf4;
          }
        }
      }

      * {
        color: #568bf4 !important;
      }
    }

    &--epic {
      &.space-3-radio-wrapper-checked {
        border-color: #e09a0f !important;
        .space-3-radio-checked::after {
          border-color: #e09a0f !important;
        }

        .space-3-radio-input:focus + .space-3-radio-inner {
          border-color: #e09a0f;
        }

        .space-3-radio-inner {
          border-color: #e09a0f;
          &::after {
            background-color: #e09a0f;
          }
        }
      }

      * {
        color: #e09a0f !important;
      }
    }

    &--legendary {
      &.space-3-radio-wrapper-checked {
        border-color: #d62cf3 !important;
        .space-3-radio-checked::after {
          border-color: #d62cf3 !important;
        }

        .space-3-radio-input:focus + .space-3-radio-inner {
          border-color: #d62cf3;
        }

        .space-3-radio-inner {
          border-color: #d62cf3;
          &::after {
            background-color: #d62cf3;
          }
        }
      }

      * {
        color: #d62cf3 !important;
      }
    }
  }
}

@ant-prefix: space-3;