.dropdown-filter {
  border: none;
  min-width: 125px;
  div[class*='select-selector'] {
    border: 1px solid #242f30;
    border-radius: 8px !important;
  }
  .space-3-btn-compact-first-item {
    display: none !important;
  }
  .space-3-btn-compact-last-item {
    padding: 0 16px;
    color: #42bab0;
  }
}
.dropdown-create-bounty {
  .space-3-btn-compact-first-item {
    display: none !important;
  }
  .space-3-btn-compact-last-item {
    padding: 8px;
    color: #1e1e1e !important;
    span {
      color: inherit;
    }
  }
}

@ant-prefix: space-3;