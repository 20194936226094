.space-3-btn.btn-create-new-section {
  &-overview {
    background: #a246db !important;
  }

  &-system-featured {
    background: #db4646 !important;
  }

  &-featured-content {
    background: #5866f2 !important;
  }

  &-team-members {
    background: #c28222 !important;
  }

  &-company {
    background: #42bab0 !important;
  }

  &-official-link {
    background: #cc0066 !important;
  }
}

@ant-prefix: space-3;