@import 'static/styles/common/vars.less';

.leaderboard-task-management {
  .leaderboard-game-tabs {
    .space-3-tabs-nav {
      margin: 0;
      min-height: 46px;
    }
  }
}

.btn-action {
  &-warning {
    background: fade(@WARNING, 10%) !important;
    border-radius: 6px !important;
    overflow: hidden;

    &:hover {
      background: fade(@WARNING, 30%) !important;
    }
  }

  &-danger {
    background: fade(@ERROR, 10%) !important;
    border-radius: 6px !important;
    overflow: hidden;

    &:hover {
      background: fade(@ERROR, 30%) !important;
    }

    button[disabled] {
      span {
        color: fade(@ERROR, 50%) !important;
      }
    }
  }
}

.column-space-action {
  .space-3-space-item {
    height: 40px !important;
  }
}

@ant-prefix: space-3;