@import 'static/styles/common/vars';

.table-border-collapse {
  .gacha-row-action-btn {
    &:hover {
      .anticon svg {
        fill: @PRIMARY !important;
      }
    }
  }
}

.export-gacha-reward-modal {
  .space-3-modal-content {
    background-color: transparent;
  }

  .space-3-modal-body {
    border-radius: 12px;
    border: 2px solid #242f30;
    background-color: #0a0c0e;
    padding: 40px;
  }

  .logo {
    width: 96px;
    height: 96px;
    text-align: center;
  }

  .title {
    color: #0fdbd1 !important;
    font-size: 24px;
    letter-spacing: 0.2px;
  }

  .space-3-select-selector {
    border-radius: 8px !important;
    border: 1px solid #29333d !important;
  }

  .btn-submit {
    border-radius: 8px;
    background-color: #0fdbd1 !important;
    span {
      color: #1e1e1e;
    }

    &:hover {
      background-color: @PRIMARY !important;
    }
  }
}

@ant-prefix: space-3;