.logistic-step {
  .space-3-steps-item {
      &-active {
      .space-3-steps-item-container {
        .space-3-steps-item {
          &-title,
          &-description {
            color: #42bab0 !important;
          }
        }
      }
    }

    &-finish {
      .space-3-steps-item-container {
        .space-3-steps-item {
          &-title,
          &-description {
            color: #4d5a66 !important;
          }
          &-icon {
            .space-3-steps-icon-dot {
              background: #4d5a66 !important;
            }
          }

          &-tail {
            &::after {
              background-color: #4d5a66 !important;
            }
          }
        }
      }
      
    }

    &-wait {
      .space-3-steps-item-container {
        .space-3-steps-item {
          &-title,
          &-description {
            color: #fff !important;
          }
          // &-icon {
          //   .space-3-steps-icon-dot {
          //     background: #42bab0 !important;
          //   }
          // }
         
        }
      }
    }

    // &-process {
    //   .space-3-steps-item-container {
    //     .space-3-steps-item {
    //       &-tail {
    //         &::after {
    //           background-color: #42bab0 !important;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@ant-prefix: space-3;