/**
 * Available vars: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */

@import '~antd/dist/antd.dark.less';
@import './common/vars.less';
@import './common/polyfill.less';
@import './common/scrollbar.less';
@import './common/typography.less';
@import './common/form.less';

// Core
@height-base: 40px;
@height-lg: 48px;
@height-sm: 32px;
@disabled-bg: @DISABLED_BG;
@disabled-color: @DISABLED_COLOR;
@body-background: #091112;
@border-color-base: transparent;
// @component-background: @COMPONENT_BG;
@item-active-bg: @COMPONENT_BG_ACTIVE;
@item-hover-bg: @COMPONENT_BG_ACTIVE;
@background-color-light: @COMPONENT_BG;
@primary-color: @PRIMARY;
@success-color: @SUCCESS;
@error-color: @ERROR;
@border-color-split: @COMPONENT_BG_ACTIVE;

// Button
@btn-default-color: #42bab0;
@btn-primary-bg: @GRADIENT_PRIMARY;
@btn-border-width: 0;
@btn-border-radius-base: 0;
@btn-default-border: transparent;
@btn-default-ghost-color: #42bab0;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: #42bab0;
@btn-text-hover-bg: transparent;

// Text
@text-color: @white;
@text-color-secondary: @SECONDARY;
@font-family: 'Inter', sans-serif;
@heading-color: @white;

// Input
@input-placeholder-color: @SECONDARY;
@input-bg: @INPUT_BG;
@input-addon-bg: @INPUT_BG;

// Card
@card-background: @CARD_BG;
@card-padding-base: 24px;
@card-radius: 0;
@card-head-padding: 6px;
@card-head-background: @COMPONENT_BG;
@card-head-height: 38px;

// Menu
@menu-dark-color: @DISABLED_TEXT;
@menu-dark-bg: @DARK;
@menu-item-boundary-margin: 0;
@menu-item-vertical-margin: 0;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;

// Typography
@typography-title-margin-top: 0;
@typography-title-margin-bottom: 0;

// Table
@table-header-bg: transparent;
@table-bg: transparent;
@table-row-hover-bg: @COMPONENT_BG;
@table-border-color: transparent;

// Select
@select-dropdown-bg: @COMPONENT_BG;
@select-background: @INPUT_BG;
@select-selection-item-bg: @COMPONENT_BG_ACTIVE;
@select-selection-item-border-color: @COMPONENT_BG_ACTIVE;
@table-selected-row-color: @COMPONENT_BG;
@table-selected-row-bg: @COMPONENT_BG;
@select-border-color: @border-color-split;
@select-item-selected-color: @primary-color;
// Dropdown
@dropdown-selected-bg: @COMPONENT_BG_ACTIVE;

// Collapse
@collapse-header-padding: 10px 16px;

// Pagination
@pagination-item-bg: transparent;
@pagination-item-link-bg: transparent;
@pagination-item-bg-active: transparent;

// Radio
@radio-button-color: @primary-color;
@radio-button-hover-color: @primary-color;
@radio-button-active-color: @primary-color;

// Tabs
// @tabs-bar-margin: 0;
@tabs-hover-color: @primary-color;
@tabs-active-color: @primary-color;

// Switch
@switch-bg: @white;

// CheckBox
@checkbox-check-bg: #b6b6b6;
@checkbox-check-color: @white;
@checkbox-color: @primary-color;
@checkbox-border-radius: 4px;
@checkbox-border-width: 1.5px;

// Drawer
@drawer-bg: #091112;

//Modal
@modal-content-bg: @COMPONENT_BG;

// Patch
body {
  background-color: #070d0e;
}

.@{ant-prefix}-btn-background-ghost {
  border-width: 1px;
}

.clip-path {
  min-height: 32px;
  clip-path: polygon(
    10px 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 10px
  );
}

// dropdown
.dropdown-btn {
  .@{ant-prefix}-btn {
    width: auto;
  }
  .space-3-dropdown-trigger {
    height: 100%;
    cursor: pointer;
  }
  .space-3-btn-compact-first-item {
    display: none;
  }
  &.pad-less {
    .space-3-dropdown-trigger {
      padding: 0 !important;
    }
  }
}
.space-3-dropdown {
  &.sp3-drop-overlay {
    .space-3-dropdown-menu {
      border-radius: 8px;
      padding: 0 !important;
      overflow: hidden;
      .space-3-dropdown-menu-item {
        padding: 12px;
        &:hover {
          background: #242f30;
        }
      }
    }
    .space-3-dropdown-menu-item-icon {
      font-size: 16px;
    }
  }
}

// Image wrapper full width
.@{ant-prefix}-image {
  width: 100%;
}

// Checkbox
.@{ant-prefix}-radio-inner {
  border: 1px solid @COMPONENT_BG_ACTIVE;
}

// Btn filled
.btn-filled {
  border: 1px solid #242f30;
  border-radius: 6px;
  background: #42bab0;
  color: #1e1e1e;
}

// Tabs
.space-3-tabs-tab {
  &:not(.space-3-tabs-tab-active) {
    .space-3-tabs-tab-btn {
      color: #4d5a66;
    }
  }
}

.@{ant-prefix}-btn-primary {
  &:hover,
  &:active,
  &:focus {
    background: @GRADIENT_PRIMARY_HOVER;
  }
}

@ant-prefix: space-3;