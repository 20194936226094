@import 'static/styles/common/vars';

.whitelist-container {
  .card-whitelist {
    &:hover {
      border-color: @SUCCESS !important ;
    }
  }
}

@ant-prefix: space-3;