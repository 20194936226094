.upload-img {
  border: 1px dashed #4d5a66 !important;
  background: transparent !important;
  width: 109px !important;
  height: 109px !important;
}

.display-img {
  position: relative;
  width: 109px;
  height: 109px;
  &-btn {
    display: none !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    .display-img-content {
      opacity: 0.5 !important;
    }
    .display-img-btn {
      opacity: 0.5 !important;
      display: block !important;
    }
  }
}

@ant-prefix: space-3;